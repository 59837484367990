import React from "react";
import { PlainLayout } from "../../components/Layout";
import styled from "styled-components";
import { RegisterForm } from "../../containers/Forms/RegisterForm";
import { Button } from "../../components/Common";
import { defaultButton } from "../../components/Common/Button";
import { LoginForm } from "../../containers/Forms";
import { useAppDispatch } from "../../store";
import { registerUser } from "../../store/reducers/authReducer";
import { useDesktop } from "../../components/Responsive";
import { useMediaQuery } from "@material-ui/core";
import { Link } from "./Login";

export const Card = styled.div`
  background: #ffffff;
  display: flex;
  padding: 55px 76px 68px 69px;
  @media (max-width: 1024px) {
    padding: 55px 20px 68px 20px;
  }
`;

const Form = styled.div`
  border: 1px solid #d1dfe5;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 70px 50px;
  justify-content: center;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 127%;
  /* identical to box height, or 32px */

  text-align: center;

  color: #1b607b;
`;

const Label2 = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 150%;
  /* or 18px */

  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  color: #1b607b;
`;

const Label3 = styled.div`
  font-family: "Baloo 2", sans-serif;
  font-style: normal;
  font-size: 30px;
  line-height: 120%;
  font-weight: 600;
  max-width: 600px;
  /* or 36px */

  color: #1b607b;
`;

const Label4 = styled.div`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  margin-top: 35px;
  color: #0b4560;
  max-width: 474px;
`;

const Ul = styled.ul`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  max-width: 550px;
  color: #1b607b;
  margin-top: 20px;
  list-style-type: disc;
  margin-left: 16px;
`;

const Img = styled.img`
  margin-top: 20px;
`;

function Register({}: IRegister) {
  const dispatch = useAppDispatch();
  const onSubmit = (values: any) => {
    dispatch(registerUser({ ...values }));
  };

  const desktop = useMediaQuery(
    "(min-width:1300px) and (orientation: landscape)"
  );

  return (
    <PlainLayout>
      <Card>
        {desktop && (
          <div
            style={{
              marginRight: "90px",
            }}
          >
            <Label3>
              Zarejestruj się jako firma i korzystaj z benefitów jakie oferuje
              nasz panel.
            </Label3>
            <Label4>Po rejestracji:</Label4>
            <Ul>
              <li>Łatwo i szybko dokonasz sadzenia drzewa</li>
              <li>
                Wygenerujesz spersonalizowany certyfikat (na przykład dla swoich
                Klientów lub pracowników)
              </li>
              <li>
                Wybierzesz odpowiedni dla siebie sposób płatności (wygenerować
                proformę lub zapłacić szybkim przelewem)
              </li>
              <li>
                Otrzymasz swoją stronę firmową i w łatwy sposób będziesz nią
                zarządzać
              </li>
            </Ul>
            <Img alt={"register"} src={"/static/img/register.png"} />
          </div>
        )}
        {desktop ? (
          <Form>
            <Label>Zarejestruj się</Label>
            <Label2>Potwierdzenie rejestracji otrzymasz przez email.</Label2>
            <RegisterForm onSubmit={onSubmit}>
              <Button
                style={{
                  maxHeight: "45px",
                  minHeight: "45px",
                  marginTop: "25px",
                  width: "350px",
                }}
                type={"submit"}
                styles={defaultButton}
                variant={"contained"}
              >
                ZAREJESTRUJ SIĘ
              </Button>
              <Link to={"/logowanie"}>Masz już konto? Zaloguj się.</Link>
            </RegisterForm>
          </Form>
        ) : (
          <RegisterForm onSubmit={onSubmit}>
            <Button
              style={{
                maxHeight: "45px",
                minHeight: "45px",
                marginTop: "25px",
                width: "350px",
              }}
              type={"submit"}
              styles={defaultButton}
              variant={"contained"}
            >
              ZAREJESTRUJ SIĘ
            </Button>
            <Link to={"/logowanie"}>Masz już konto? Zaloguj się.</Link>
          </RegisterForm>
        )}
      </Card>
    </PlainLayout>
  );
}

export interface IRegister {}

export default Register;
