import * as React from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import { Error, TextInput } from "../Common/TextInput";
import { Button } from "../Common";
import { defaultButton } from "../Common/Button";
import { useHistory } from "react-router";
import { CompanyInfoForm } from "../../containers/Forms";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import {
  fetchCompany,
  ICompany,
  updateCompany,
} from "../../store/reducers/companyReducer";
import { fetchPublic } from "../../store/reducers/publicReducer";
import { formatPrice, toNumber } from "../../utils/price";
import {
  createOrder,
  IOrder,
  setOrder,
} from "../../store/reducers/orderReducer";
import PurchaseFromInfo from "./PurchaseFromInfo";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

export function PurchaseSecondStep({}) {
  const history = useHistory();
  const companyId = useAppSelector((state) => state.auth.companyId);
  const company = useAppSelector((state) => state.company.company);
  const trees = useAppSelector((state) => state.user.user?.totalPlantedTrees);
  const count = useAppSelector((state) => state.order.treeCount);
  const paymentRedirect = useAppSelector(
    (state) => state.order.paymentRedirectURL
  );
  const [errors, setErrors] = useState("");
  const groupId = useAppSelector((state) => state.order.orderGroup);
  const dispatch = useAppDispatch();
  const [submiting, setSubmiting] = useState(false);

  const [type, setType] = useState("payu_company");

  useEffect(() => {
    if (paymentRedirect && !paymentRedirect.includes(window.location.origin)) {
      window.location.href = paymentRedirect;
    }
  }, [paymentRedirect]);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany({}));
    }
  }, [companyId]);

  const onSubmitForm = (values: Partial<ICompany>, e: any) => {
    if (!company?.companyBillingAddress || !company?.companyBillingCity) {
      dispatch(updateCompany(Object.assign({}, company, {...values, companyBillingVATNumber: values?.companyBillingVATNumber?.replaceAll(' ', '')?.replaceAll('-', '')?.replaceAll('PL', '')})));
    }

    setSubmiting(true);

    setTimeout(() => {
      const mapValues = (
        values: Partial<ICompany>
      ): Pick<
        IOrder,
        | "customerAddress"
        | "customerCity"
        | "customerName"
        | "customerVatNumber"
        | "customerZipCode"
      > => {
        return {
          customerAddress: values.companyBillingAddress || "",
          customerCity: values.companyBillingCity || "",
          customerName: values.companyBillingName || "",
          customerVatNumber: values.companyBillingVATNumber?.replaceAll(' ', '')?.replaceAll('-', '')?.replaceAll('PL', '') || "",
          customerZipCode: values.companyBillingZipCode || "",
        };
      };

      dispatch(
        setOrder({
          paymentType: type == "cash" ? 1 : 0,
        })
      );
      if (!submiting) {
        dispatch(
          createOrder(
            Object.assign(
              {
                ...mapValues(values),
                quantity: count,
                paymentMethod: type as any,
                paymentReturnURL:
                  window.location.origin +
                  `/thank-you?total=${count + (trees || 0)}&trees=${count}`,
              },
              groupId ? { treeGroupId: Number(groupId) } : {}
            )
          )
        ).then((c) => {
          if (c.payload.status === 422) {
            setErrors(c.payload.message);
          }
          if (type === "cash" && c.payload.status !== 422) {
            history.push(`/thank-you?trees=${count}`);
          }
          setSubmiting(false);
        });
      }
    }, 200);
  };

  const submit = (type: string) => () => {
    setType(type);
  };

  return (
    <Wrapper>
      <CompanyInfoForm
        externalErrors={errors}
        initial={company}
        onSubmit={onSubmitForm}
      >
        <PurchaseFromInfo submit={submit} />
      </CompanyInfoForm>
    </Wrapper>
  );
}
