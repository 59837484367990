import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./reset.css";
import { Provider } from "react-redux";
import { store } from "./store";
import "./utils/b64";
import { Router } from "react-router-dom";
import history from "./history";
import LogRocket from "logrocket";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5TD3DMZ",
};

TagManager.initialize(tagManagerArgs);

if (!window.location.pathname.includes("widgety")) {
  LogRocket.init("zhfkby/posadzimy");
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
