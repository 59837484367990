import React from "react";
import { useForm } from "react-hook-form";
import { IForm } from "./index";
import { TextInput } from "../../components/Common/TextInput";

const fields = [
  {
    name: "companyContactName",
    label: "Imię i nazwisko",
    type: "text",
    placeholder: "Imię i nazwisko",
    rules: {
      required: true,
    },
  },
  {
    name: "companyContactEmail",
    label: "E-mail",
    type: "text",
    placeholder: "E-mail",
    rules: {
      required: true,
    },
  },
  {
    name: "companyContactPhone",
    label: "Telefon",
    type: "number",
    placeholder: "Telefon",
    rules: {
      required: true,
    },
  },
];

function ContactForm({ onSubmit, children, initial }: IContactForm) {
  const { register, handleSubmit, setValue, errors, reset } = useForm();
  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      register(field.name, field.rules);
    });
  });

  React.useEffect(() => {
    if (initial) {
      reset(initial);
    }
  }, [initial]);

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, i) => (
        <TextInput
          wrapperStyle={i === 0 ? { marginTop: "0" } : {}}
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          error={errors[field.name] && "Uzupełnij pole"}
          ref={register(field.rules)}
          key={field.name}
          type={field.type}
          onChange={handleChange}
          defaultValue={initial && initial[field.name]}
        />
      ))}
      {children}
    </form>
  );
}

export interface IContactForm extends IForm {}

export default ContactForm;
