import * as React from "react";
import styled, { css } from "styled-components";
import { NumberPicker } from "./NumberPicker";
import { NumberInput } from "../Common/NumberInput";
import { Button } from "../Common";
import { defaultButton } from "../Common/Button";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  CountType,
  createOrder,
  IOrder,
  setOrder,
  setTreeCount,
} from "../../store/reducers/orderReducer";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import PurchaseFromInfo from "./PurchaseFromInfo";
import { useHistory } from "react-router";
import { fetchCompany, ICompany } from "../../store/reducers/companyReducer";
import debounce from "lodash/debounce";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 100%;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #1b607b;
`;

export function PurchaseFirstStep({ nextStep, modal, ecommerce }: any) {
  const order = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const trees = useAppSelector((state) => state.user.user?.totalPlantedTrees);
  const count = useAppSelector((state) => state.order.treeCount);
  const comapny = useAppSelector((state) => state.company.company);
  const companyId = useAppSelector((state) => state.auth.companyId);
  const groupId = useAppSelector((state) => state.order.orderGroup);
  const paymentRedirect = useAppSelector(
    (state) => state.order.paymentRedirectURL
  );

  const [error, setError] = useState(false);
  const [submiting, setSubmiting] = useState(false);

  useEffect(() => {
    if (paymentRedirect && !paymentRedirect.includes(window.location.origin)) {
      window.location.href = paymentRedirect;
    }
  }, [paymentRedirect]);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany({}));
    }
  }, [companyId]);

  const changePicker = (r: number) => {
    dispatch(
      setTreeCount({
        count: r,
        type: CountType.Select,
      })
    );
  };

  const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (ecommerce && Number(e.currentTarget.value) < 10) {
      setError(true);
    } else {
      setError(false);
    }

    if (
      e.currentTarget.value.includes(".") ||
      e.currentTarget.value.includes(",")
    ) {
      return;
    }

    dispatch(
      setTreeCount({
        count: Number(e.currentTarget.value),
        type: CountType.Input,
      })
    );
  };

  const changeArrow = (v: number) => () => {
    if (ecommerce && order.treeCount + v < 10) {
      setError(true);
    } else {
      setError(false);
    }
    dispatch(
      setTreeCount({
        count: order.treeCount + v,
        type: CountType.Input,
      })
    );
  };

  const submitRaw = (paymentType: "payu_company" | "cash") => () => {
    if (ecommerce && count < 10) {
      return setError(true);
    }

    setSubmiting(true);

    dispatch(
      setOrder({
        paymentType: paymentType == "cash" ? 1 : 0,
      })
    );

    const mapValues = (
      values: Partial<ICompany>
    ): Pick<
      IOrder,
      | "customerAddress"
      | "customerCity"
      | "customerName"
      | "customerVatNumber"
      | "customerZipCode"
    > => {
      return {
        customerAddress: values.companyBillingAddress || "",
        customerCity: values.companyBillingCity || "",
        customerName: values.companyBillingName || "",
        customerVatNumber: values.companyBillingVATNumber || "",
        customerZipCode: values.companyBillingZipCode || "",
      };
    };

    if (!submiting) {
      dispatch(
        createOrder(
          Object.assign(
            {
              ...mapValues(comapny || {}),
              quantity: count,
              paymentMethod: paymentType,
              orderType: ecommerce ? "credits" : "trees",
              paymentReturnURL:
                window.location.origin +
                `/thank-you?total=${count + (trees || 0)}&trees=${count}`,
            },
            groupId ? { treeGroupId: Number(groupId) } : {}
          )
        )
      ).then((c) => {
        if (paymentType === "cash" && c.payload.status !== 422) {
          history.push(
            ecommerce
              ? `/thank-you?trees=${count}&orderType=credits`
              : `/thank-you?trees=${count}`
          );
        }
        setSubmiting(false);
      });
    }
  };

  const submit = submitRaw;

  return (
    <Wrapper>
      <Label>
        {ecommerce
          ? "Wybierz liczbę drzew, którymi chcesz doładować konto"
          : "Wybierz ile drzew chcesz zasadzić"}
      </Label>
      <NumberPicker
        onChange={changePicker}
        range={[10, 20, 50, 100]}
        value={order.countType === CountType.Select ? order.treeCount : 0}
      />
      <NumberInput
        value={order.countType === CountType.Input ? order.treeCount : ""}
        onChange={changeInput}
        placeholder={ecommerce ? "inna liczba (minimum 10)" : "inna liczba"}
        error={error ? "Minimalne doładowanie to 10 drzew" : false}
        up={changeArrow(1)}
        down={changeArrow(-1)}
      />
      {modal ? (
        <PurchaseFromInfo
          error={error}
          ecommerce={ecommerce}
          submit={submit}
          modal={modal}
        />
      ) : (
        <Button
          style={{ marginTop: "15px" }}
          styles={defaultButton}
          variant={"contained"}
          onClick={nextStep}
        >
          DALEJ
        </Button>
      )}
    </Wrapper>
  );
}
