import * as React from "react";
import { useForm } from "react-hook-form";
import { IForm } from "./index";
import { TextInput } from "../../components/Common/TextInput";
import { Checkbox, TextAreaInput } from "../../components/Common";
import { useAppSelector } from "../../store";

const fields = [
  {
    name: "companyName",
    label: "Nazwa firmy",
    type: "text",
    placeholder: "Nazwa użytkownika",
    rules: {
      required: true,
    },
  },
  {
    name: "email",
    label: "Firmowy adres email",
    type: "text",
    placeholder: "Firmowy adres email",
    rules: {
      required: true,
    },
  },
  {
    name: "password",
    label: "Hasło",
    type: "text",
    placeholder: "Hasło",
    rules: {
      required: true,
      validate: (value: string) => {
        const re = new RegExp(
          /(?=.*\d)(?=.*[a-z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{6,}/
        );
        if (value === "" || value == null) {
          return true;
        }
        return re.test(value);
      },
    },
  },
  {
    name: "agreement",
    label:
      'Potwierdzam, że zapoznałem się i akceptuję <a onclick="(function(e) { e.stopPropagation(); return true;} )(arguments[0]); return true;" href="https://posadzimy.pl/wp-content/uploads/2021/08/Regulamin-serwisu-internetowego-1.pdf" target="_blank">regulamin serwisu</a> internetowego oraz jego <a onclick="(function(e) { e.stopPropagation(); return true;} )(arguments[0]); return true;" href="https://posadzimy.pl/wp-content/uploads/2021/08/Polityka-prywatnosci-serwisu-internetowego.pdf" target="_blank">politykę prywatności.</a>',
    type: "checkbox",
    rules: {
      required: true,
    },
  },
  {
    name: "isAgreementForNewsletter",
    label:
      "Chcę otrzymywać Newsletter z najciekawszymi informacjami dotyczącymi projektu, promocjami i treściami edukacyjnymi. W każdej chwili możesz się z niego wpisać.",
    type: "checkbox",
  },
];

export function RegisterForm({
  children,
  onSubmit,
  initial = undefined,
}: IRegisterForm) {
  const { register, handleSubmit, setValue, errors, watch, reset } = useForm();
  const error = useAppSelector((state) => state.auth.failedRegister);

  console.log(error);
  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      register(field.name, field.rules);
    });
  }, []);

  React.useEffect(() => {
    if (initial) {
      reset(initial);
    }
  }, [initial]);

  return (
    <form
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      onSubmit={handleSubmit(watch("agreement") ? onSubmit : () => {})}
    >
      {fields.map((field, i) =>
        field.type === "text" ? (
          <TextInput
            wrapperStyle={
              i === 0 ? { marginTop: "0", width: "300px" } : { width: "300px" }
            }
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            type={i == 2 ? "password" : "text"}
            error={
              (errors[field.name] &&
                (i == 2
                  ? "Hasło musi się składać z min 8 znaków, 1 specjalnego i cyfry"
                  : "Uzupełnij pole")) ||
              (error &&
                i === 1 &&
                ((error as any)?.message?.includes("exists")
                  ? "Ten adres email jest zajęty"
                  : "Ten adres email jest nieprawidłowy"))
            }
            key={field.name}
            onChange={handleChange}
          />
        ) : (
          <Checkbox
            name={field.name}
            key={field.name}
            label={field.label}
            error={errors[field.name]}
            onChange={handleChange}
            defaultValue={false}
            labelStyle={{ fontSize: "11px" }}
            wrapperStyle={{ marginTop: "25px", maxWidth: "300px" }}
          />
        )
      )}
      {children}
    </form>
  );
}

export interface IRegisterForm extends IForm {}
